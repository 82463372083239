var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "space-between" } },
        [
          _c("el-button", [
            _vm._v(_vm._s(_vm.temp.isReprint === 0 ? "原创" : "转载")),
          ]),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bgFFF" },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "150px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "资讯 ID:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.informationId) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "状态:" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("statusFilter")(_vm.temp.status)) +
                            " "
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "500px" },
                          attrs: { "label-width": "0" },
                        },
                        [
                          _c(
                            "el-carousel",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { height: "200px" },
                            },
                            _vm._l(
                              _vm.temp.infoPreviewImgList,
                              function (item) {
                                return _c("el-carousel-item", { key: item }, [
                                  _c("img", {
                                    staticStyle: { height: "200px" },
                                    attrs: { src: item },
                                  }),
                                ])
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "标题:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.title) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "简介:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.introduction) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "作者:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.author) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "分类:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.infoCateFullName) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "区域:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.publishCityStr) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "是否置顶:" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("isStickFilter")(_vm.temp.isStick)) +
                            " "
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "发布渠道:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.releaseTypeStr) + " "),
                      ]),
                      _c("el-form-item", [
                        _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                          _vm._v(
                            "阅读数（" + _vm._s(_vm.temp.readNumber) + "）:"
                          ),
                        ]),
                        _vm._v(
                          " 基础值（" +
                            _vm._s(_vm.temp.readBasics) +
                            "）+实际用户操作值（" +
                            _vm._s(_vm.temp.userRead) +
                            "）*放大倍数（" +
                            _vm._s(_vm.temp.readMultiple) +
                            "） "
                        ),
                      ]),
                      _c("el-form-item", [
                        _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                          _vm._v(
                            "点赞数（" + _vm._s(_vm.temp.pointNumber) + "）:"
                          ),
                        ]),
                        _vm._v(
                          " 基础值（" +
                            _vm._s(_vm.temp.pointBasics) +
                            "）+实际用户操作值（" +
                            _vm._s(_vm.temp.userPoint) +
                            "）*放大倍数（" +
                            _vm._s(_vm.temp.pointMultiple) +
                            "） "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "定时上架时间:" } },
                        [_vm._v(" " + _vm._s(_vm.temp.upperTime) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "定时下架时间:" } },
                        [_vm._v(" " + _vm._s(_vm.temp.lowerTime) + " ")]
                      ),
                      _c("el-form-item", { attrs: { label: "分享标题:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.shareTitle) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "分享描述:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.shareDescribe) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "分享缩略图:" } }, [
                        _c("img", {
                          staticStyle: { height: "100px" },
                          attrs: { src: _vm.temp.shareImgUrl },
                        }),
                      ]),
                      _c("el-form-item", { attrs: { label: "预览链接:" } }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.baseUrl) +
                              "?id=" +
                              _vm._s(_vm.informationId)
                          ),
                        ]),
                      ]),
                      _c("el-form-item", { attrs: { label: "最后操作人:" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.updateUserName) + " "),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "最后修改时间:" } },
                        [_vm._v(" " + _vm._s(_vm.temp.updateTime) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "初始创建时间:" } },
                        [_vm._v(" " + _vm._s(_vm.temp.createTime) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }